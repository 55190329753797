.ngb-dp-header {
  background-color: var(--grey-8) !important;
  color: var(--light) !important;
}

.ngb-dp-months {
  background-color: var(--grey-8) !important;
  color: var(--light) !important;
}

.ngb-dp-weekdays {
  background-color: var(--grey-8) !important;
  color: var(--light) !important;
  border-bottom: none !important;
}

.ngb-dp-day {
  .btn-light {
    color: var(--light) !important;
    &:hover {
      background-color: var(--primary-tint);
      border-color: var(--primary-tint);
      color: var(--grey-11) !important;
    }
    &:active {
      background-color: var(--primary-tint);
      border-color: var(--primary-tint);
    }
  }
  .text-white {
    color: var(--grey-11) !important;
  }
}

.light-ngbDatepicker {
  .ngb-dp-header {
    background-color: var(--light) !important;
    color: var(--grey-8) !important;
  }

  .ngb-dp-months {
    background-color: var(--light) !important;
    color: var(--grey-8) !important;
  }

  .ngb-dp-weekdays {
    background-color: var(--light) !important;
    color: var(--grey-8) !important;
  }

  .ngb-dp-day {
    .btn-light {
      color: var(--grey-8) !important;
    }
  }
}

.ngb-dp-arrow {
  .btn-link {
    color: var(--grey-6) !important;
  }
  .btn:active,
  .btn:focus {
    outline: none !important;
    box-shadow: none;
  }
}

.rangePicker-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.rangePicker-day.focused {
  background-color: #e6e6e6;
}

.rangePicker-day.range,
.rangePicker-day:hover {
  background-color: var(--primary-tint);
  color: var(--grey-11);
}

.rangePicker-day.faded {
  background-color: rgba(92, 255, 164, 0.5);
}
