$border-radius-lg: 8px;
$input-btn-padding-y-lg: 14px;
$input-btn-padding-x-lg: 37px;
$input-bg: #ffffff;
$input-border-color: var(--grey-3);
$input-color: var(--grey-10);
$input-placeholder-color: var(--grey-6);
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-disabled-bg: transparent;
$line-height-sm: 0;
$primary: #60cc9f;
$secondary: #afb4bb;
$dark: #575757;
$light: #d7dce1;
$danger: #ff6565;
$info: #006acb;
$success: #59ca8f;
$warning: #f6d982;
$enable-validation-icons: false;
$box-shadow-sm: 0px 2px 4px 2px rgba(0, 0, 0, 0.02),
  0px 2px 4px 0px rgba(0, 0, 6, 0.08);
