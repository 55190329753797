.ant-design-blue-5 {
  color: var(--blue-5);
}
.ant-design-gold-5 {
  color: var(--gold-5);
}
.ant-design-volcano-5 {
  color: var(--volcano-5);
}

.border-dashed {
  border: 1px dashed;
}

.ant-body {
  font-size: 14px;
  line-height: 22px;
}

.ant-body-strong {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
