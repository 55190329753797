@import 'scss/bootstrap-variables';
@import 'scss/variables';
@import 'scss/ngb-datepicker';
@import 'scss/form';

/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/flag-icon-css/css/flag-icon.min.css';

/* importing Ag Grid CSS file. */
@import 'node_modules/ag-grid-community/styles/ag-grid.min.css';
@import 'node_modules/ag-grid-community/styles/ag-theme-alpine.min.css';
@import 'scss/ag-grid';
@import 'scss/ag-theme-wilson';

// regular style toast
@import 'node_modules/ngx-toastr/toastr';

// outlined badge styles
@import 'scss/status-badge';

// priority tag styles
@import 'scss/priority-tag';

@import 'elements';
@import 'scss/tooltip';
@import 'scss/toast';
@import 'scss/utilities';

@font-face {
  font-family: 'Roboto Flex';
  src: url('./assets/fonts/RobotoFlex.ttf');
}

body {
  font-family: 'Roboto Flex', sans-serif;
  overflow-y: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.btn {
  box-shadow: none !important;
}

.btn-primary {
  color: var(--grey-8);
}

.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:disabled {
  background-color: var(--background);
  border-color: var(--background);
  opacity: 0.5;
}

.btn-dark:hover {
  background-color: var(--grey-10);
  border-color: var(--grey-10);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
  border: 0 !important;
  padding: 0 !important;
}

.search-bar {
  border: 1px solid var(--background) !important;
}

.list-header {
  font-size: 13px;
  color: var(--grey-6);
}

#toast-container > .toast-success {
  background-color: var(--success-tint);
  color: var(--grey-8);
}

#toast-container > .toast-error {
  background-color: var(--red-5);
  color: var(--light);
}

#toast-container > .toast-info {
  background-color: var(--blue-5);
  color: var(--light);
}

.link-dark:hover,
.text-primary-hover:hover {
  color: var(--primary) !important;
}

.form-scroll {
  height: calc(100vh - 14rem);
  overflow-x: hidden;
}

.form-text {
  margin-top: 0.25rem;
  color: var(--grey-6);
}

.all-partner-header {
  .ag-header-cell-text {
    font-weight: bold;
    font-size: 1rem;
    color: var(--grey-8);
  }
}

.ant-popover-inner-content {
  padding: 12px 16px;
}

.custom-popover {
  .ant-popover-inner-content {
    padding: 6px 8px;
  }
}

wilson-company-settings {
  .ant-tabs-ink-bar {
    width: 4px !important;
  }
}

.mapboxgl-control-container {
  display: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--light);
  height: 2px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #999999;
  border-color: #999999 !important;
  border-radius: 3px;
}

.invoice-pdf-preview {
  .pdfViewer {
    padding: 10px;

    .page {
      box-shadow: 0 0 5px 2px var(--background);
    }
  }
}

.cdk-virtual-scroll-spacer {
  height: 1px !important;
}

.ant-skeleton-element .ant-skeleton-input-lg {
  height: 100%;
  width: 100%;
}

.filter-title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: var(--grey-6);
}

.layout-card {
  padding: 1rem;
  border-radius: 12px;
  background: var(--light);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-right: 8px;
  flex-shrink: 0;
  background-color: currentColor;

  &-small {
    width: 12px;
    height: 12px;
  }
}

nz-select.select-multiple-no-wrap {
  nz-select-top-control {
    flex-wrap: nowrap !important;
  }

  nz-select-item {
    flex: inherit !important;
  }
}

.NgxEditor__Wrapper.readonly-editor {
  border: none;

  .NgxEditor__Content {
    padding: 0.5rem 0;
    pointer-events: initial !important;
  }
}

.ant-btn-primary,
.btn-primary {
  border-color: var(--wilson);
  background: var(--wilson);
}

.rotate-0 {
  transform: rotate(0deg);
  transition-duration: 500ms;
}

.rotate-180 {
  transform: rotate(180deg);
  transition-duration: 500ms;
}
