.dark {
  @import '../../../../node_modules/ng-zorro-antd/ng-zorro-antd.dark.less';

  @text-color: var(--light);
  @input-placeholder-color: var(--grey-6);
  @input-hover-border-color: var(--grey-10);

  @select-border-color: var(--grey-10);
  @select-selection-item-bg: var(--transparent);
  @select-dropdown-bg: var(--grey-10);
  @select-selection-item-border-color: var(--grey-5);
  @select-item-selected-bg: var(--primary-light);
  @select-item-selected-color: var(--grey-10);
  @select-item-selected-font-weight: 400;
  @select-multiple-item-disabled-color: var(--grey-8);

  nz-select {
    width: 100%;
  }

  .ant-select-item-option-state {
    color: var(--grey-10) !important;
  }

  .ant-select-dropdown {
    border: none !important;
    border-radius: 6px;
  }

  .ant-select-selector {
    background-color: var(--grey-10) !important;
    border: 1px solid var(--grey-10) !important;
  }

  .ant-select-selector:hover {
    background-color: var(--grey-10) !important;
    border: 1px solid var(--grey-10) !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background-color: var(--grey-10) !important;
    border: 1px solid var(--grey-6) !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: var(--grey-6) !important;
    box-shadow: none;
  }

  .ant-select-clear {
    color: var(--grey-8);
    background: transparent;
  }

  .ant-select-clear:hover {
    color: var(--grey-8);
    background: transparent;
  }

  .anticon {
    vertical-align: 0em !important;
  }

  ::-webkit-scrollbar {
    width: 1.5rem;
    overflow-y: scroll;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--grey-8);
    border-radius: 15px;
    border: 0.675rem solid #2e2e2e;
  }

  .ant-picker {
    background: var(--grey-10) !important;
    border-radius: 6px !important;
    width: 100%;
  }

  .ant-picker-focused {
    border-color: var(--grey-10) !important;
    box-shadow: none;
  }

  .ant-picker-panel-container {
    background: var(--grey-10) !important;
  }

  .ant-picker-today-btn {
    color: var(--light);
  }

  .ant-picker-today-btn:hover {
    color: var(--primary-light);
  }

  .ant-picker-header-view button:hover {
    color: var(--primary-light);
  }

  .ant-picker-range .ant-picker-active-bar {
    background: var(--primary-light) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: var(--grey-10) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    color: var(--grey-10) !important;
    background: rgba(92, 255, 164, 0.5) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before {
    background: rgba(92, 255, 164, 0.5) !important;
    left: 25px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before {
    background: rgba(92, 255, 164, 0.5) !important;
    right: 25px;
  }

  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: var(--primary-light) !important;
  }

  .ant-picker-week-panel-row td {
    color: var(--light);
  }

  .ant-picker-week-panel-row-selected td {
    background: var(--primary-light) !important;
    color: var(--grey-10) !important;
  }

  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner {
    color: var(--grey-10) !important;
  }

  .ant-tooltip-inner {
    background-color: var(--grey-10) !important;
    border-radius: 6px !important;
  }

  .ant-divider-horizontal {
    margin: 10px 0px;
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: var(--grey-10) !important;
  }

  .ant-select-tree {
    background-color: var(--grey-10) !important;
    color: var(--light) !important;
  }

  .ant-select-tree-switcher {
    line-height: 20px !important;
  }

  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: var(--primary-light) !important;
    border-color: var(--primary-light) !important;
  }

  .ant-select-tree-checkbox-inner {
    border-color: var(--grey-10) !important;
  }

  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    border-color: var(--grey-10) !important;
  }

  .ant-select-tree-checkbox-indeterminate
    .ant-select-tree-checkbox-inner::after {
    background-color: var(--primary-light) !important;
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background: rgba(255, 255, 255, 0.08) !important;
    border-radius: 0 !important;
  }

  .font-highlight {
    color: var(--primary-light) !important;
  }
}
