.badge {
  padding: 0.4em 0.75em;
  font-size: 12px;
}

.badge-primary {
  background: rgba(89, 202, 143, 0.1) !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}

.badge-primary-dark {
  background: rgba(28, 152, 97, 0.1) !important;
  border: 1px solid var(--primary-shade) !important;
  color: var(--primary-shade) !important;
}

.badge-warning {
  background: rgba(252, 172, 100, 0.1) !important;
  border: 1px solid var(--orange) !important;
  color: var(--orange) !important;
}

.badge-danger {
  background: rgba(198, 48, 59, 0.1) !important;
  border: 1px solid var(--red-5-dark) !important;
  color: var(--red-5-dark) !important;
}

.badge-info {
  background: rgba(0, 106, 203, 0.1) !important;
  border: 1px solid var(--blue-7) !important;
  color: var(--blue-7) !important;
}

.badge-secondary {
  background: rgba(175, 180, 187, 0.1) !important;
  border: 1px solid var(--grey-6) !important;
  color: var(--grey-6) !important;
}

.badge-secondary-dark {
  background: rgba(87, 87, 87, 0.1) !important;
  border: 1px solid var(--grey-8) !important;
  color: var(--grey-8) !important;
}

.badge-white {
  background: var(--light) !important;
  border: 1px solid var(--grey-5) !important;
  color: var(--grey-10) !important;
}
