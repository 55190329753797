// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@btn-primary-color: var(--grey-10);
@primary-color: #60cc9f;
@card-shadow: none;
@border-radius-base: 6px;

// Border color
@border-color-base: var(--grey-6);

@input-bg: var(--light);
@text-color: var(--grey-10);
@input-placeholder-color: var(--grey-6);
@input-hover-border-color: var(--grey-10);

@select-border-color: var(--grey-6);
@select-selection-item-bg: var(--grey-6);
@select-dropdown-bg: var(--light);
@select-selection-item-border-color: var(--grey-6);
@select-item-selected-bg: rgba(92, 255, 164, 0.2);
@select-item-selected-font-weight: 400;
@select-multiple-item-disabled-color: var(--grey-8);

nz-select {
  width: 100%;
}

.ant-select-item-option-state {
  color: var(--primary) !important;
}

.ant-select-dropdown {
  border: 1px solid var(--grey-5) !important;

  ::-webkit-scrollbar {
    width: 1.5rem;
    overflow-y: scroll;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--grey-8);
    border-radius: 15px;
    border: 0.675rem solid var(--light);
  }
}

.ant-select-selector {
  background-color: var(--light);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--grey-8);
  box-shadow: 0 0 0 2px #e5e5e5;
}

.ant-select-clear {
  color: var(--grey-8);
}

.ant-select-clear:hover {
  color: var(--grey-8);
}

.ant-select-selection-item {
  border-radius: 2px !important;
}

.anticon {
  vertical-align: 0em !important;
}

.ant-picker {
  width: 100%;
  border-radius: 2px;
}

.ant-picker-panel-container {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid var(--grey-5);

  .ant-picker-header {
    border-bottom: none;
    padding-top: 5px;
  }

  .ant-picker-header-view {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    .ant-picker-header-view button:not(:first-child) {
      margin-right: 8px;
      margin-left: 0px;
    }
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-month-panel {
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: initial;
    }
  }

  .ant-picker-year-panel {
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: initial;
    }
  }

  .ant-picker-decade-panel {
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: initial;
    }
  }

  .ant-picker-body {
    padding: 0 5px 10px;
  }

  .ant-picker-content {
    thead th {
      font-weight: bold;
      padding-bottom: 10px;
    }
  }

  .ant-picker-week-panel-row-selected {
    td:first-child {
      // , .ant-picker-cell-week + td
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
  .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
    color: inherit;
  }
}

.ant-picker-dropdown {
  &.theme-dark {
    .ant-picker-panel-container {
      background-color: var(--grey-10);
      color: var(--light);
    }

    .ant-picker-panel {
      border: 1px solid var(--grey-10);
    }

    .ant-picker-header {
      color: inherit;

      button {
        color: inherit;
      }
    }

    .ant-picker-cell-inner {
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 80%;
    }

    .ant-picker-cell-in-view {
      color: var(--light);
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: var(--grey-11);
      color: var(--light);
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end):not(
        .ant-picker-cell-range-hover-start
      ):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background-color: var(--primary-light);
      color: var(--grey-10);
    }
  }
}

.light-sharp-corners {
  .ant-tooltip-inner {
    border-radius: 0 !important;
    padding: 0;
  }
}

.ant-tooltip-inner {
  color: var(--grey-10);
  background-color: var(--light) !important;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: var(--light) !important;
}

.ant-popover-inner-content {
  padding: 5px 0px;
}

.ant-menu-item {
  color: var(--grey-10);
}

.ant-menu-item:hover {
  color: var(--grey-10) !important;
  background: var(--grey-6);
}

.ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child {
  border-right-color: var(--primary);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: var(--primary);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--primary);
  background: #fff;
  border-color: var(--primary);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--primary);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: var(--primary);
  border-color: var(--primary);
}

.ant-radio-button-wrapper:hover {
  color: var(--primary);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  color: var(--primary);
  background-color: var(--primary);
}

.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  display: flex;
  align-items: center;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: flex;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
}

.ant-steps-item-icon .ant-steps-icon {
  top: -1.5px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-description {
  max-width: 350px;
}

.ant-input-group-compact {
  .ant-select {
    .ant-select-selector {
      border-radius: 2px 0px 0px 6px;
      width: auto;
      background: var(--light-light);
      height: auto;
    }
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 2px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 2px;
}

.ant-input-affix-wrapper {
  border-radius: 2px;
}

.input-increment-decrement-icons {
  .ant-input-wrapper {
    .ant-input-group-addon {
      padding: 0;
    }
  }
}

.shift-template-dropdown {
  .ant-select-item {
    padding: 0;
    height: 45px;
    line-height: 15px;
  }

  cdk-virtual-scroll-viewport {
    min-height: 45px;
  }
}

.collapse-panel .ant-collapse-header {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.azm-warning-alert .ant-alert-with-description {
  padding: 5px 5px 5px 15px !important;
}

.ant-menu-item-selected {
  background-color: rgba(92, 255, 164, 0.2) !important;
}

.ant-menu-submenu-selected {
  color: var(--primary-dark-light) !important;
}

.ant-menu-submenu-title:hover {
  color: var(--primary-dark-light) !important;
}

.ant-menu-vertical .ant-menu-submenu-selected {
  color: unset;
  background-color: rgba(92, 255, 164, 0.2) !important;
}

.ant-menu-vertical .ant-menu-submenu-title:hover {
  color: var(--grey-10) !important;
  background: var(--grey-6);
  color: unset;
}

.ant-menu .ant-menu-item-selected {
  color: var(--grey-10) !important;
}

.ant-menu-item-selected a,
.ant-menu-item a:hover,
.ant-menu-item-selected a:hover {
  color: var(--primary-dark-light) !important;
}

.nz-upload-list-before {
  display: grid;

  nz-upload-list {
    order: -1;
    overflow: hidden;
    margin-bottom: 6px;

    .ant-upload-list-item-name {
      color: var(--grey-7);
    }
  }
}

.shift-attachments-upload .ant-upload-list {
  display: block;
  overflow: hidden;
  padding: 10px;
  margin: 0px;
  height: 100%;
}

.shift-attachments-upload-no-attachments .ant-upload-list {
  padding: 0px;
  margin: 0px;
  height: 100%;
}
