.priority-one {
  background-color: var(--magenta-1);
  border: 1px solid var(--magenta-6);
  color: var(--magenta-9);
}

.priority-two {
  background-color: var(--red-1);
  border: 1px solid var(--red-5);
  color: var(--red-8);
}

.priority-three {
  background-color: var(--volcano-1);
  border: 1px solid var(--volcano-4);
  color: var(--volcano-8);
}

.priority-four {
  background-color: var(--gold-1);
  border: 1px solid var(--gold-6);
  color: var(--gold-8);
}

.priority-five {
  background-color: var(--cyan-1);
  border: 1px solid var(--cyan-3);
  color: var(--cyan-8);
}
