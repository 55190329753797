@import 'prem';

.cdk-overlay-container {
  // needed so element/cdk overlays do not immediately open below ngb overlays
  z-index: 5000;
}

.modal-body {
  padding: 0 1.5rem;
  //padding-left: 1.5rem;
  //padding-right: 1.5rem;
}

.modal-header {
  position: relative;
  flex-wrap: wrap;
  border-bottom: none;

  padding: 1.5 * $prem;
  padding-top: 3 * $prem;

  > * {
    margin-left: $prem;
    margin-right: $prem;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    margin: $prem 0 0;
    border-bottom: $modal-footer-border-width solid $modal-footer-border-color;
  }

  .modal-close {
    border: none;
    background: none;
    color: var(--grey-6);
    align-self: flex-start;
    transition: all 0.2s ease-in;

    &:hover,
    &:focus {
      color: var(--grey-10);
    }
  }
}

.modal-footer {
  border-top: none;

  padding: 1.5 * $prem;

  > * {
    margin-left: $prem;
    margin-right: $prem;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    margin: 0 0 1.5 * $prem;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
  }
}

.collapsable-toggle {
  display: flex;

  .collapse-icon {
    margin-left: auto;
    color: var(--grey-6);
  }
}

.btn-plain {
  color: var(--grey-8);
  text-decoration: underline;

  &:hover,
  &:focus {
    color: var(--grey-10);
  }
}

.btn-like {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus {
    color: var(--primary) !important;
  }
}

.footer-actions {
  list-style-type: none;
  display: flex;
  margin: 1 * $prem 0 0 0;
  padding: 0;

  justify-content: space-between;
  font-size: 1.3 * $prem;
  color: var(--grey-8);

  .btn {
    padding: 0;

    color: inherit;
    font-size: inherit;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: var(--grey-10);
    }
  }
}

.dropdown.context-menu,
.dropup.context-menu {
  //$dropdown-context-gutter: 2 *$prem;
  $dropdown-context-gutter: 1.6 * $prem;

  // NOW: paddings .8*$prem 1.6*$prem
  .dropdown-menu {
    font-size: 14px;

    padding: $dropdown-context-gutter/2 0;
    border-radius: 0;
    box-shadow: 0 4px 4px rgba(#000, 0.25);

    .dropdown,
    .dropup {
      .dropdown-toggle {
        cursor: pointer;
        padding: $dropdown-context-gutter/2 $dropdown-context-gutter;

        .nested-dropdown-icon {
          float: right;
        }

        &:hover,
        &:focus,
        &:active {
          @include gradient-bg($dropdown-link-hover-bg);
        }

        &::after {
          display: none;
        }
      }
    }

    // TODO: this is a workaround for nested menu positioning (it should not be a workaround, but the ngbDropdown does neither position things properly nor supports nested menus
    .context-menu .dropdown-menu.show {
      left: calc(100% + 1px) !important;
      top: calc(-100% - 9px) !important;

      &.place-left {
        left: initial !important;
        right: calc(100%) !important;
      }
    }
  }

  .dropdown-item {
    padding: $dropdown-context-gutter/2 $dropdown-context-gutter;

    .fa,
    .far,
    .icon {
      font-size: 1.2em;
      margin-inline-start: 0.5rem;
      margin-inline-end: 0.5rem;
    }
  }

  .dropdown-content {
    padding: $dropdown-context-gutter;
  }

  .dropdown-divider {
    margin-left: $dropdown-context-gutter/2;
    margin-right: $dropdown-context-gutter/2;

    + .dropdown-content {
      padding-top: 0;
    }
  }
}

.preview-box {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    text-align: left;

    font-size: 13px;
    background: var(--grey-8);
    color: var(--light);

    padding: 1.75 * $prem 2 * $prem;

    //min-width: 47.5 * $prem;
    min-width: 40 * $prem;
  }

  .tooltip-arrow {
    color: var(--grey-8);
  }

  $placements: top, right, bottom, left;
  @each $placement in $placements {
    &[data-popper-placement^='#{$placement}'] .tooltip-arrow::before,
    &.bs-tooltip-#{$placement} .tooltip-arrow::before {
      border-#{$placement}-color: var(--grey-8);
    }
  }
  &.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: var(--grey-8);
  }
  &.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: var(--grey-8);
  }

  h1,
  h2 {
    color: var(--primary-tint);
    font-weight: bold;
    font-size: inherit;

    margin-bottom: 1 * $prem;
  }

  dl {
    display: flex;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;

    dt {
      margin-right: 0.5 * $prem;
      margin-bottom: 0.75 $prem;
    }

    dd {
      margin-right: 2 * $prem;
      margin-bottom: 0;
    }

    .break {
      width: 100%;
    }
  }
}
