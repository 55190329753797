.ag-theme-alpine {
  --ag-selected-row-background-color: var(--primary-tint);
}

.ag-theme-alpine .ag-root-wrapper {
  border: 0;
}

.ag-theme-alpine .ag-header {
  border-bottom: 1px solid var(--background);
  background-color: var(--light);
}

.ag-theme-alpine .ag-header-cell-text {
  font-size: 13px;
  font-weight: normal;
  color: var(--grey-6);
}

.ag-theme-alpine .ag-row {
  height: 60px !important;

  &:hover {
    background-color: var(--grey-1);
  }
}

.ag-theme-alpine .ag-row-odd {
  background-color: var(--light) !important;

  &:hover {
    background-color: var(--grey-1) !important;
  }
}

.ag-theme-alpine .ag-cell-focus,
.ag-theme-alpine .ag-cell {
  border: none !important;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1325px !important;
  }
}

.ag-theme-alpine .ag-selected-row-secondary .ag-row-selected {
  background-color: var(--grey-1) !important;
}

.ag-theme-alpine .ag-center-cols-clipper {
  min-height: 75px !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
  height: 15px;
  width: 15px;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--primary-tint) !important;
}

.ag-theme-alpine .ag-row {
  border-color: var(--background) !important;
}

.ag-theme-alpine .ag-header-row {
  border-color: var(--background) !important;
}

.ag-theme-alpine .ag-selection-checkbox {
  margin-top: 0.565rem !important;
}

.ag-theme-alpine .ag-row-disabled {
  background-color: var(--grey-1) !important;
  opacity: 50% !important;
}

.ag-theme-striped .ag-header {
  border-bottom: none;
  background-color: var(--light);
}

.ag-theme-striped .ag-row-even {
  background-color: rgba(240, 243, 245, 0.5) !important;
  &:hover {
    background-color: rgba(240, 243, 245, 0.5) !important;
  }
}

.ag-theme-striped .ag-row-odd {
  background-color: var(--light) !important;
  &:hover {
    background-color: var(--light) !important;
  }
}

.ag-theme-striped .ag-row {
  height: 50px !important;
}

.ag-theme-striped .ag-cell {
  padding-left: 20px !important;
}

.ag-theme-striped .ag-header-cell {
  padding-left: 20px !important;
}

.ag-theme-striped .ag-checkbox-input-wrapper input,
.ag-theme-striped .ag-checkbox-input-wrapper input {
  -webkit-appearance: none;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.ag-theme-striped .ag-checkbox-input-wrapper::after {
  content: '\f108';
  color: #999;
  color: var(--ag-checkbox-unchecked-color, #999);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.ag-theme-striped .ag-checkbox-input-wrapper {
  font-family: 'agGridAlpine';
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  flex: none;
}

.ag-theme-striped .ag-checkbox-input-wrapper.ag-checked::after {
  content: '\f106';
  color: var(--primary-tint) !important;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.ag-theme-alpine .ag-selection-checkbox {
  margin-top: 1rem;
}

.row-disabled {
  background-color: var(--grey-1);
  opacity: 50%;
}

.ag-theme-segment-header .ag-row-even {
  background-color: var(--light) !important;
}

.ag-theme-segment-header .ag-row-odd {
  background-color: var(--light) !important;
}

.ag-theme-segment-header .ag-row {
  border: none;
  background-color: var(--light) !important;
}

.ag-theme-segment-header .ag-header {
  border: none;
}

.ag-theme-segment-header .ag-cell-focus,
.ag-theme-segment-header .ag-cell {
  padding-bottom: 0px;
}

.ag-grid-header-to-right {
  .ag-header-cell-label {
    justify-content: end;
  }
}
