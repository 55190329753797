.icon-square {
  padding: 1.2rem;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  text-align: center;

  i {
    font-size: 2.5rem;
  }
}

.form-icon {
  max-width: 100px;
}

.force-disabled {
  opacity: 0.6;
  color: var(--grey-6);

  cursor: not-allowed;

  input,
  select,
  textarea,
  wilson-dropdown {
    pointer-events: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.form-check-input:focus {
  box-shadow: none !important;
}

.form-control {
  padding: 0.188rem 0.75rem !important;
  border-radius: 6px !important;
  border: 1px solid var(--grey-6) !important;
}

.form-control.is-invalid {
  border-color: var(--red-5) !important;
}

.form-control-disabled:disabled,
.form-control-disabled[readonly] {
  width: 100%;
  color: rgba(0, 0, 0, 0.25) !important;
  padding: 0.188rem 0.75rem !important;
  border-radius: 6px !important;
  border: 1px solid var(--grey-6) !important;
  background-color: var(--grey-3) !important;
}

.form-control-disabled:focus-visible {
  outline: none !important;
}
