$prem: 10px; // this is a base 10 calc helper and default sizing unit, the way 1rem=10px usually would work

$shift-cal-leading-w: 20.8 * $prem;
$shift-cal-leading-w-in: 18.2 * $prem;
$shift-cal-pag-width: 1.6 * $prem;
$shift-cal-day-width: 16.7 * $prem;
$shift-cal-grid-gutter: 0.6 * $prem;

$shift-cal-el-height: 3 * $prem;
$shift-cal-row-height: 4.5 * $prem;
