tooltip.tooltip {
  padding: 0 !important;
  background: none !important;

  &:after {
    content: none !important;
  }
}
.tooltip.tooltip-top,
.tooltip.tooltip-bottom,
.tooltip.tooltip-left,
.tooltip.tooltip-right {
  z-index: 100000;
}

.tooltip-container {
  background-color: var(--grey-8);
  color: var(--light);
  padding: 20px 20px 30px 20px;
  border-radius: 6px;
  font-size: 11px;
  text-align: left;
}

.tooltip.tooltip {
  min-width: 150px;
}
.tooltip.tooltip {
  max-width: 475px;
}
